import { PRICING_FAQ } from 'constants/faq';
import { PRICING_PERIODS, PRICING_PLANS } from 'constants/pricing';
import { MAIN_SCHEMA } from 'constants/schema';
import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import { useState } from 'react';
import HomePageComponent from 'style-book/home-page/HomePageComponent';
import SeoComponent from 'style-book/seo/SeoComponent';
import SwitchComponent from 'style-book/switch/SwitchComponent';
import TextComponent from 'style-book/text/TextComponent';
import FaqSection from 'widgets/sections/faq/FaqSection';
import PrincingPlanWidget from 'widgets/sections/pricing/PrincingPlanWidget';

import './PricingPage.scss';

export default function PricingPage() {
  const [isYearlyBilling, setIsYearlyBilling] = useState(false);
  return (
    <HomePageComponent className="pricing-page">
      <div className="pricing-page-content">
        <div className="text-container">
          <TextComponent className="page-title" variant={TEXT_VARIANTS.TITLE}>
            Simplified Digital Marketing Success
          </TextComponent>
          <TextComponent className="description" variant={TEXT_VARIANTS.HEADING}>
            Unlock Success for Your Small Business, No Matter Your Role in Marketing. Whether you
            wear multiple hats, handle digital marketing solo, or lead a team, our plans are
            tailored to empower you with actionable insights and drive growth.
          </TextComponent>
          <TextComponent className="note" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            Try us free for 14 days.
          </TextComponent>
        </div>
        <div className="plan-settings">
          <SwitchComponent
            className="billing-period"
            defaultChecked={isYearlyBilling}
            labelEnd={true}
            nagtiveLabel="Monthly"
            positiveLabel="Yearly"
            nagtiveClassName="monthly-period"
            positiveClassName="yearly-period"
            onValueChange={(yearlyBilling) => setIsYearlyBilling(yearlyBilling)}
          />
          <div className="plan-notes">
            <TextComponent className="note" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              12 months for the price of 10 when you pay yearly.
            </TextComponent>
            <TextComponent className="note" variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
              All prices in USD.
            </TextComponent>
          </div>
        </div>
        <div className="plans-container">
          <div className="plans">
            {Object.values(PRICING_PLANS).map((plan) => (
              <PrincingPlanWidget
                key={plan.id}
                period={isYearlyBilling ? PRICING_PERIODS.YEARLY : PRICING_PERIODS.MONTHLY}
                {...plan}
              />
            ))}
          </div>
        </div>
      </div>
      <FaqSection faq={PRICING_FAQ} />
    </HomePageComponent>
  );
}

export function Head() {
  return (
    <SeoComponent
      title="goalskeeper.io | pricing"
      description="Transforming Data into Success: Goalskeeper's Powerful Solution. Set Goals, Visualize Insights, and Achieve Business Milestones."
      canonicalUrl="goalskeeper.io/pricing/"
      schema={MAIN_SCHEMA}
    />
  );
}
